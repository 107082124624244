<template>
    <q-menu
        v-model="isMenuOpen" 
        :target="targetElement" 
        :anchor-origin="menuAnchor" 
        :self-origin="menuSelf"
        @hide="onMenuClose" 
        fit
    >
        <q-list>
            <bar-share :title="title" :link="link" class="q-ma-sm" />
        </q-list>
    </q-menu>
</template>

<script setup>
import { ref, defineExpose, defineProps } from 'vue'

import BarShare from "@/modules/main/components/barShare";


defineProps({
    link: String,
    title: String
})

const isMenuOpen = ref(false)
const targetElement = ref(null)
const menuAnchor = 'bottom middle'
const menuSelf = 'top middle'


// ================== METHODS =========================

/* Método para abrir o menu a partir de um componente externo */
const openMenu = (element) => {
    targetElement.value = element  // Define o botão clicado como alvo
    isMenuOpen.value = true  // Abre o menu
}


/* Método para fechar o menu */
const onMenuClose = () => {
    isMenuOpen.value = false;  // Define o estado do menu como fechado
    targetElement.value = null;  // Limpa a referência do elemento alvo
};

// ================== METHODS =========================


/* Expõe o método openMenu para ser usado em componentes externos */
defineExpose({
    openMenu
})


</script>
<style scoped>
:deep(.ic-share button:last-child) {
    margin-left: 0.5rem
}
</style>